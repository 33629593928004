import error404 from '../views/error/404.vue';

export default [
    {
        path: '/404',
        meta: {
            title: '404 Not Found',
            requireLogin: false,
        },
        component: error404,
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/404',
    },
];
