//微信js-sdk
import wx from 'weixin-js-sdk';

export default {

    /**
     * 微信Api初始化
     * @param res.appId 公众号的唯一标识
     * @param res.timestamp 生成签名的时间戳
     * @param res.nonceStr 生成签名的随机串
     * @param res.signature 签名
     */
    wxRegister(res) {
        wx.config({
            debug: false, // 开启调试模式
            appId: res.appId, // 必填，公众号的唯一标识
            timestamp: res.timestamp , // 必填，生成签名的时间戳
            nonceStr: res.nonceStr, // 必填，生成签名的随机串
            signature: res.signature,// 必填，签名
            jsApiList: [// 必填，需要使用的JS接口列表
                'updateAppMessageShareData',
                'updateTimelineShareData',
                'onMenuShareWeibo'
            ],
            openTagList: ['wx-open-launch-app'],
        });
    },

    /**
     *
     * @param options
     * @param options.title 标题
     * @param options.desc 描述
     * @param options.url 链接地址
     * @param options.imgUrl 图片地址
     * @param successCallBack 成功回调函数
     * @param cancelCallBack 失败回调函数
     */
    shareL(options, successCallBack, cancelCallBack) {
        wx.ready(function(){
            wx.updateAppMessageShareData({
                title: options.title, // 分享标题
                desc: options.desc, // 分享描述
                link: options.url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: options.imgUrl, // 分享图标
                success: function () {
                    if(successCallBack) {
                        successCallBack();
                    }
                },
            });

            wx.updateTimelineShareData({
                title: options.title, // 分享标题
                link: options.url, // 分享链接
                imgUrl: options.imgUrl, // 分享图标
                success: function () {
                    if(successCallBack) {
                        successCallBack();
                    }
                },
                cancel: function () {
                    if(cancelCallBack) {
                        cancelCallBack();
                    }
                }
            });

            wx.onMenuShareWeibo({
                title: options.title, // 分享标题
                desc: options.desc, // 分享描述
                link: options.url, // 分享链接
                imgUrl: options.imgUrl, // 分享图标
                success: function () {
                    if(successCallBack) {
                        successCallBack();
                    }
                },
                cancel: function () {
                    if(cancelCallBack) {
                        cancelCallBack();
                    }
                }
            });
        })
    },
}