import "core-js/modules/es.array.push.js";
import topNav from "../../components/common/topNav.vue";
import footWrap from "../../components/common/footWrap.vue";
import { Button, Space, Popup, PullRefresh, List, Empty } from 'vant';
export default {
  data() {
    return {
      scenario: 'news',
      postData: {
        type: 0,
        page: 0
      },
      data: [],
      refreshing: false,
      loading: false,
      finished: false
    };
  },

  components: {
    topNav,
    footWrap,
    [Button.name]: Button,
    [Space.name]: Space,
    [Popup.name]: Popup,
    [PullRefresh.name]: PullRefresh,
    [List.name]: List,
    [Empty.name]: Empty
  },
  watch: {
    $route(n, o) {
      // 监听路由参数是否变化
      if (n.path == o.path && n.fullPath !== o.fullPath) {
        this.postData.type = this.$route.query.type;
        this.refresh();
      }
    }

  },

  setup() {},

  created() {
    // 初始化postData
    this.setQuery();
  },

  activated() {
    if (this.$route.query.type != this.postData.type) {
      this.postData.type = this.$route.query.type;
      this.refresh();
    }
  },

  methods: {
    getData() {
      this.http('/www/home/news-list', this.postData, res => {
        // 数据渲染
        for (let i in res.list.data) {
          this.data.push(res.list.data[i]);
        } // 我是有底限的


        if (res.list.data.length < 1) {
          this.finished = true;
        } // 刷新、加载完成


        this.loading = false;
        this.refreshing = false;
      }, null, 2);
    },

    refresh() {
      this.finished = false;
      this.data = [];
      this.postData.page = 0;
      this.loading = true;
      this.load();
    },

    load() {
      this.postData.page = this.postData.page + 1;
      this.getData();
    }

  }
};