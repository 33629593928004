import { Popup, Form, Field, CellGroup, Button } from 'vant';
export default {
  components: {
    [Popup.name]: Popup,
    [Form.name]: Form,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    shid: {
      type: String,
      default: 0
    }
  },
  watch: {
    // 实现双向绑定
    show(val) {
      this.showModal = val;

      if (val === false) {
        this.post = {};
      }
    },

    showModal(val) {
      if (val === false) {
        this.$emit("submit", val);
      }
    }

  },
  // 定义抛出的事件名称
  emits: ['submit'],

  data() {
    return {
      showModal: this.show,
      post: {}
    };
  },

  created() {},

  methods: {
    onSubmit() {
      let url = '';

      if (this.shid) {
        this.post.shid = this.shid;
        url = '/www/home/share-match';
      } else {
        url = '/www/home/share-save';
      }

      this.http(url, this.post, res => {
        this.showModal = false;
        this.toast({
          message: '您的共享需求已提交，我们会尽快审核您提交的信息。'
        });
        this.post = {};
        this.$emit("submit");
      }, null, 2);
    }

  }
};