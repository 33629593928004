import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-2b3ebb80"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "wrap"
};
const _hoisted_2 = {
  class: "demand-tab content-height"
};
const _hoisted_3 = ["onKey"];
const _hoisted_4 = {
  class: "demand-company"
};
const _hoisted_5 = {
  class: "date"
};
const _hoisted_6 = {
  class: "demand-info"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_top_nav = _resolveComponent("top-nav");

  const _component_van_tab = _resolveComponent("van-tab");

  const _component_van_tabs = _resolveComponent("van-tabs");

  const _component_van_button = _resolveComponent("van-button");

  const _component_van_list = _resolveComponent("van-list");

  const _component_van_empty = _resolveComponent("van-empty");

  const _component_van_pull_refresh = _resolveComponent("van-pull-refresh");

  const _component_gong_xu = _resolveComponent("gong-xu");

  const _component_foot_wrap = _resolveComponent("foot-wrap");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_top_nav), _createElementVNode("div", _hoisted_2, [_createVNode(_component_van_tabs, {
    active: $setup.active,
    "onUpdate:active": _cache[0] || (_cache[0] = $event => $setup.active = $event),
    onChange: $options.changeTab,
    background: "transparent",
    color: "#1483CC",
    "title-active-color": "#1483CC",
    "line-width": "32px"
  }, {
    default: _withCtx(() => [_createVNode(_component_van_tab, {
      name: "1",
      title: "供给",
      "title-class": "demand-tab-nav"
    }), _createVNode(_component_van_tab, {
      name: "2",
      title: "需求",
      "title-class": "demand-tab-nav"
    })]),
    _: 1
  }, 8, ["active", "onChange"]), _createVNode(_component_van_pull_refresh, {
    modelValue: $data.refreshing,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.refreshing = $event),
    onRefresh: $options.refresh,
    "success-text": "刷新成功"
  }, {
    default: _withCtx(() => [_createVNode(_component_van_list, {
      loading: $data.loading,
      "onUpdate:loading": _cache[1] || (_cache[1] = $event => $data.loading = $event),
      finished: $data.finished,
      "finished-text": $data.data.length > 0 ? '我是有底线的' : '',
      onLoad: $options.load,
      offset: "0"
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.data, (item, key) => {
        return _openBlock(), _createElementBlock("div", {
          class: "demand-web-list flex",
          onKey: key
        }, [_createElementVNode("div", _hoisted_4, [_createElementVNode("h3", null, _toDisplayString(item.cname), 1), _createElementVNode("p", _hoisted_5, _toDisplayString(item.addtime_str), 1), _createElementVNode("p", _hoisted_6, _toDisplayString(item.content), 1)]), _createElementVNode("div", {
          class: _normalizeClass('apply ' + (item.type == '1' ? 'gong' : 'xu'))
        }, [_createVNode(_component_van_button, {
          type: "default",
          round: "",
          onClick: $event => $options.sub(item.shid)
        }, {
          default: _withCtx(() => [_createTextVNode("申请")]),
          _: 2
        }, 1032, ["onClick"])], 2)], 40, _hoisted_3);
      }), 256))]),
      _: 1
    }, 8, ["loading", "finished", "finished-text", "onLoad"]), $data.data.length == 0 ? (_openBlock(), _createBlock(_component_van_empty, {
      key: 0,
      image: "/img/empty.png",
      "image-size": "80",
      description: "暂时还没有新闻哦~"
    })) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["modelValue", "onRefresh"]), _createVNode(_component_gong_xu, {
    show: $data.show,
    shid: $data.shid,
    onSubmit: $options.submit
  }, null, 8, ["show", "shid", "onSubmit"])]), _createVNode(_component_foot_wrap)]);
}