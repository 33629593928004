import schoolCoop from '../views/school/schoolCoop.vue'

export default [
    {
        path: '/school',
        name: 'schoolCoop',
        meta: {
            title: '精工培养',
            requireLogin: false,
        },
        component: schoolCoop,
    },
    
];