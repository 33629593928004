/**
 * 公用函数
 */

import axios from 'axios';
import qs from 'qs';
import VueCookies from "vue-cookies";
import wx from 'weixin-js-sdk';
import wxShare from "./wxShare";

export default {

    getWechatOpenid(obj) {

        let openid = this.getCookie('openid');
        let code = obj.query.code;

        if (!openid) {
            if (!code) {// 获取微信code
                let uri = process.env.NODE_ENV === 'production' ? window.location.href : 'http://m.xschr.com/';
                let redirect_uri = encodeURIComponent(uri);
                let url = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + process.env.VUE_APP_APPID + "&redirect_uri=" + redirect_uri + "&response_type=code&scope=snsapi_base&state=#wechat_redirect";

                window.location.href = url;
                return false;
            } else {// 通过code获取openid,【登录状态绑定微信】
                this.http('/m/login/get-openid', {code: code}, (res) => {
                    if (res.data.openid) {
                        this.setCookie('openid', res.data.openid);
                        this.setCookie('platform', res.data.platform);

                        if (res.data.uid) {
                            this.loginSetCookie(res.data);
                            return true;
                        } else {
                            this.setCookie('unfastLogin', '1');
                        }
                    }
                });
            }
        } else {
            if (this.isLogin() || this.getCookie('unfastLogin') === '1') {
                return false;
            }

            this.http('/m/login/fast-login', {openid: openid}, (res) => {
                if(res.data && res.data.uid) {
                    this.loginSetCookie(res.data);
                    return true;
                } else {
                    this.setCookie('unfastLogin', '1');
                }
            });
        }

        return false;
    },
    /**
     * 登录
     * @param obj
     */
    loginSetCookie(obj) {
        this.setCookie('uid', obj.uid);
        this.setCookie('token', obj.token);
        this.removeCookie('unfastLogin');

        //this.$store.commit("setLogin", true)
    },
    /**
     * 退出登录
     */
    logoutRemoveCookie() {
        this.removeCookie('uid');
        this.removeCookie('token');

        //this.$store.commit("setLogin", false)
    },
    /**
     * 设置cookie
     * @param name
     * @param value
     * @param expires
     * @param path
     * @param domain
     * @param secure
     */
    setCookie(name, value, expires = 8640000, path='/', domain='', secure=false) {
        domain = process.env.VUE_APP_DOMAIN ? process.env.VUE_APP_DOMAIN : '';

        VueCookies.set(name, value, expires, path, domain, secure);
    },
    /**
     * 删除cookie
     * @param name
     * @param path
     * @param domain
     */
    removeCookie(name, path='/', domain='') {
        domain = process.env.VUE_APP_DOMAIN ? process.env.VUE_APP_DOMAIN : '';
        VueCookies.remove(name, path, domain);
    },
    /**
     * 获取cookie
     *
     */
    getCookie(name) {
        return VueCookies.get(name);
    },
    /**
     * 是否登录
     * @returns {boolean}
     */
    isLogin() {
        return VueCookies.get('uid') !== null && VueCookies.get('token') !== null;
    },

    /**
     * Http请求
     * @param url 请求地址
     * @param data 请求参数
     * @param successCallback 成功回调
     * @param errorCallback 错误回调
     * @param lock 是否加锁【0否、1Post、2Get】
     * @returns {boolean}
     */
    http(url, data, successCallback, errorCallback) {

        let params = this.formatParams(data);

        axios({
            method: 'post',
            url: process.env.VUE_APP_WS + url,
            data: qs.stringify(params)
        }).then( res => {

            if (successCallback) {
                successCallback(res.data);
            }

            if (errorCallback) {
                errorCallback(res.data);
            }
        }).catch(function (error) {
            console.log(error);
        });
    },
    /**
     * 格式化请求参数
     * @param data
     * @returns {{info: string}}
     */
    formatParams(data) {
        if (!data) {
            data = {};
        }

        data.terminal = this.getTerminal();
        data.dateline = parseInt((new Date()).valueOf() / 1000);

        let openid = VueCookies.get('openid');

        if (openid) {
            data.openid = openid;
            data.platform = VueCookies.get('platform') ? VueCookies.get('platform') : '';
        }

        if (this.isLogin()) {
            data.uid = VueCookies.get('uid');
            data.token = VueCookies.get('token');
        }

        return data;
    },
    /**
     * 获取终端信息【是否 android\ios\微信浏览器】
     * @returns {{isWeixin: boolean, isAndroid: boolean, isiOS: boolean}}
     */
    getTerminal() {

        if (this.inWechat() !== false) {
            return 3;
        }

        return process.env.VUE_APP_TERMINAL;
    },
    inWechat() {
        let ua = navigator.userAgent.toLowerCase();

        if (ua.match(/MicroMessenger/i) == "micromessenger") {
            return true;
        }

        return false;
    },
    /**
     * 获取微信分享配置信息
     * @param url
     */
    getWechatShareConfig(url) {
        if (url.indexOf('http') === -1) {
            url = process.env.VUE_APP_M + url;
        }

        this.http('/m/wechat/share', {url: url}, (res) => {
            wxShare.wxRegister({
                appId: res.data.appId,
                timestamp: res.data.timestamp,
                nonceStr: res.data.nonceStr,
                signature: res.data.signature
            });

            let param = {
                title: '您的工作，我们的追求 - 肥差求职招聘平台， 爱工作、爱生活、爱沃客！',
                desc: '肥差招聘平台是爱沃客旗下专注于为求职者找工作的服务平台，找工作上肥差！',
                imgUrl: 'https://m.feichaijob.com/image/feichai.png',
                url: url,
            };
            wxShare.shareL(param);

            //ios的ua中无miniProgram，但都有MicroMessenger（表示是微信浏览器）
            wx.miniProgram.getEnv((res)=>{
                if (res.miniprogram) {
                    let obj = {
                        title: param.title,
                        desc: param.desc,
                    }
                    wx.miniProgram.postMessage({
                        data: obj
                    });
                }
            })
        });
    },
    /**
     * 获取微信分享配置信息
     * @param url
     */
    getJobWechatShare(url, param) {
        if (url.indexOf('http') === -1) {
            url = process.env.VUE_APP_M + url;
        }

        this.http('/m/wechat/share', {url: url}, (res) => {

            wxShare.wxRegister({
                appId: res.data.appId,
                timestamp: res.data.timestamp,
                nonceStr: res.data.nonceStr,
                signature: res.data.signature
            });

            let title = '';

            if (param.base.istop == 1) {
                title = title + '【急招】';
            }

            title = title + param.base.sname + '正在招聘' + param.base.jobname + '，点击查看详情';

            let params = {
                title: title,
                desc: '肥差招聘平台是爱沃客旗下专注于为求职者找工作的服务平台，找工作上肥差！',
                imgUrl: param.base.logo ? param.base.logoUrl : 'https://m.feichaijob.com/image/feichai.png',
                url: url,
            };
            wxShare.shareL(params);

            //ios的ua中无miniProgram，但都有MicroMessenger（表示是微信浏览器）
            wx.miniProgram.getEnv((res)=>{
                if (res.miniprogram) {
                    let obj = {
                        title: params.title,
                        desc: params.desc,
                    }
                    wx.miniProgram.postMessage({
                        data: obj
                    });
                }
            })
        });
    },
};
