import "core-js/modules/es.array.push.js";
import { ref } from 'vue';
import topNav from "../../components/common/topNav.vue";
import footWrap from "../../components/common/footWrap.vue";
import { Button, Space, Popup } from 'vant';
export default {
  data() {
    return {
      listJG: [],
      listEJ: [],
      dataJG: {},
      dataEJ: {}
    };
  },

  components: {
    topNav,
    footWrap,
    [Button.name]: Button,
    [Space.name]: Space,
    [Popup.name]: Popup
  },

  setup() {},

  created() {
    this.getData();
  },

  methods: {
    getData() {
      // 查询数据
      this.http('/www/home/school', {
        pagesize: 3
      }, res => {
        this.listJG = res.list1.data;
        this.listEJ = res.list2.data; // this.listEJ = this.listJG;

        this.dataJG = this.listJG[0];
        this.dataEJ = this.listEJ[0];
        let listJG = [];

        for (let i in this.listJG) {
          if (i == 0) continue;
          listJG.push(this.listJG[i]);
        }

        this.listJG = listJG;
        let listEJ = [];

        for (let i in this.listEJ) {
          if (i == 0) continue;
          listEJ.push(this.listEJ[i]);
        }

        this.listEJ = listEJ;
      }, null, 2);
    }

  }
};