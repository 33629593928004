import "core-js/modules/es.array.push.js";
import topNav from "../../components/common/topNav.vue";
import footWrap from "../../components/common/footWrap.vue";
import { Tab, Tabs, Button, Sticky, PullRefresh, List, Empty } from 'vant';
import gongXu from '@/components/modal/gongxu';
import { ref } from "vue";
export default {
  components: {
    topNav,
    footWrap,
    gongXu,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Button.name]: Button,
    [Sticky.name]: Sticky,
    [PullRefresh.name]: PullRefresh,
    [List.name]: List,
    [Empty.name]: Empty
  },

  data() {
    return {
      show: false,
      postData: {
        type: 1,
        page: 0
      },
      data: [],
      refreshing: false,
      loading: false,
      finished: false,
      shid: '0'
    };
  },

  setup() {
    // tab
    const active = ref(0);
    return {
      active
    };
  },

  created() {},

  methods: {
    changeTab(type) {
      this.postData.type = type;
      this.refresh();
    },

    getData() {
      // 查询数据
      this.http('/www/home/share-list', this.postData, res => {
        // 数据渲染
        for (let i in res.list.data) {
          this.data.push(res.list.data[i]);
        } // 我是有底限的


        if (res.list.data.length < 1) {
          this.finished = true;
        } // 刷新、加载完成


        this.loading = false;
        this.refreshing = false;
      }, null, 2);
    },

    refresh() {
      this.finished = false;
      this.data = [];
      this.postData.page = 0;
      this.loading = true;
      this.load();
    },

    load() {
      this.postData.page = this.postData.page + 1;
      this.getData();
    },

    showPopup() {
      this.show = true;
    },

    submit() {
      this.show = false;
    },

    sub(shid) {
      this.shid = shid;
      this.showPopup();
    }

  }
};