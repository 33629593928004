import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "wrap"
};
const _hoisted_2 = {
  class: "news-detail"
};
const _hoisted_3 = {
  class: "news-time-column flex"
};
const _hoisted_4 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_top_nav = _resolveComponent("top-nav");

  const _component_foot_wrap = _resolveComponent("foot-wrap");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_top_nav), _createElementVNode("div", _hoisted_2, [_createElementVNode("h1", null, _toDisplayString($data.data.title), 1), _createElementVNode("div", _hoisted_3, [_createElementVNode("p", null, _toDisplayString($data.data.addtime_str), 1), _createElementVNode("p", null, [_createTextVNode("阅读"), _createElementVNode("span", null, _toDisplayString($data.data.viewnum), 1)])]), _createElementVNode("div", {
    class: "news-d-info",
    innerHTML: $data.data.content
  }, null, 8, _hoisted_4)]), _createVNode(_component_foot_wrap)]);
}