import { Swipe, SwipeItem } from 'vant';
export default {
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem
  },
  props: {
    type: {
      type: String,
      default: ''
    }
  },

  setup(props) {
    let images = [];
    let images1 = [];

    if (props.type == 'share') {
      // 共享企业
      images = ['/img/hezuo/qy-1.jpg', '/img/hezuo/qy-3.jpg', '/img/hezuo/qy-5.jpg', '/img/hezuo/qy-7.jpg', '/img/hezuo/qy-8.jpg'];
      images1 = ['/img/hezuo/gx-1.jpg', '/img/hezuo/gx-2.jpg', '/img/hezuo/gx-3.jpg', '/img/hezuo/gx-4.jpg', '/img/hezuo/gx-5.jpg'];
    } else {
      // 入池企业
      images = ['/img/hezuo/qy-1.jpg', '/img/hezuo/qy-2.jpg', '/img/hezuo/qy-3.jpg', '/img/hezuo/qy-4.jpg', '/img/hezuo/qy-5.jpg'];
      images1 = ['/img/hezuo/qy-6.jpg', '/img/hezuo/qy-7.jpg', '/img/hezuo/qy-8.jpg', '/img/hezuo/qy-9.jpg', '/img/hezuo/qy-10.jpg'];
    }

    return {
      images,
      images1
    };
  }

};