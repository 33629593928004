import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-a00bcd48"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "company-logo-dis"
};
const _hoisted_2 = {
  class: "logo-list flex"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  class: "logo-list flex"
};
const _hoisted_5 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_swipe_item = _resolveComponent("van-swipe-item");

  const _component_van_swipe = _resolveComponent("van-swipe");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_swipe, {
    class: "my-swipe",
    autoplay: 3000
  }, {
    default: _withCtx(() => [_createVNode(_component_van_swipe_item, null, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.images, image => {
        return _openBlock(), _createElementBlock("img", {
          key: image,
          src: image
        }, null, 8, _hoisted_3);
      }), 128))])]),
      _: 1
    }), _createVNode(_component_van_swipe_item, null, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.images1, image => {
        return _openBlock(), _createElementBlock("img", {
          key: image,
          src: image
        }, null, 8, _hoisted_5);
      }), 128))])]),
      _: 1
    })]),
    _: 1
  })]);
}