import topNav from "../components/common/topNav.vue";
import footWrap from "../components/common/footWrap.vue";
import companyLogo from '@/components/modal/companylogo.vue';
import { Space } from 'vant';
export default {
  components: {
    topNav,
    footWrap,
    companyLogo,
    [Space.name]: Space
  }
};