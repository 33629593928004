import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-c3b3f13e"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "wrap"
};
const _hoisted_2 = {
  class: "news-body content-height"
};
const _hoisted_3 = {
  class: "news-list"
};
const _hoisted_4 = {
  key: 0,
  class: "news-first"
};
const _hoisted_5 = ["src"];
const _hoisted_6 = {
  class: "van-multi-ellipsis--l2"
};
const _hoisted_7 = {
  class: "news-time flex align-items"
};
const _hoisted_8 = {
  class: "see"
};
const _hoisted_9 = {
  class: "news-second"
};
const _hoisted_10 = {
  class: "list"
};
const _hoisted_11 = ["onClick"];
const _hoisted_12 = {
  class: "left"
};
const _hoisted_13 = {
  class: "van-multi-ellipsis--l2"
};
const _hoisted_14 = {
  class: "news-time flex align-items"
};
const _hoisted_15 = {
  class: "see"
};
const _hoisted_16 = {
  class: "right"
};
const _hoisted_17 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_top_nav = _resolveComponent("top-nav");

  const _component_van_icon = _resolveComponent("van-icon");

  const _component_van_list = _resolveComponent("van-list");

  const _component_van_empty = _resolveComponent("van-empty");

  const _component_van_pull_refresh = _resolveComponent("van-pull-refresh");

  const _component_foot_wrap = _resolveComponent("foot-wrap");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_top_nav), _createElementVNode("div", _hoisted_2, [_createVNode(_component_van_pull_refresh, {
    modelValue: $data.refreshing,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.refreshing = $event),
    onRefresh: $options.refresh,
    "success-text": "刷新成功"
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [$data.data.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("a", {
      href: "javascript:;",
      onClick: _cache[0] || (_cache[0] = $event => _ctx.actionTo('/news/' + $data.data[0].newsid))
    }, [_createElementVNode("img", {
      src: $data.data[0].imageUrl
    }, null, 8, _hoisted_5), _createElementVNode("h3", _hoisted_6, _toDisplayString($data.data[0].description ? $data.data[0].description : $data.data[0].title), 1), _createElementVNode("div", _hoisted_7, [_createElementVNode("h4", null, _toDisplayString($data.data[0].addtime_str), 1), _createElementVNode("div", _hoisted_8, [_createVNode(_component_van_icon, {
      name: "eye-o"
    }), _createTextVNode(" " + _toDisplayString($data.data[0].viewnum), 1)])])])])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_9, [_createVNode(_component_van_list, {
      loading: $data.loading,
      "onUpdate:loading": _cache[1] || (_cache[1] = $event => $data.loading = $event),
      finished: $data.finished,
      "finished-text": $data.data.length > 0 ? '我是有底线的' : '',
      onLoad: $options.load,
      offset: "0"
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.data, (item, key) => {
        return _openBlock(), _createElementBlock("div", _hoisted_10, [key != 0 ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          href: "javascript:;",
          onClick: $event => _ctx.actionTo('/news/' + item.newsid),
          class: "flex"
        }, [_createElementVNode("div", _hoisted_12, [_createElementVNode("h3", _hoisted_13, _toDisplayString(item.description ? item.description : item.title), 1), _createElementVNode("div", _hoisted_14, [_createElementVNode("h4", null, _toDisplayString(item.addtime_str), 1), _createElementVNode("div", _hoisted_15, [_createVNode(_component_van_icon, {
          name: "eye-o"
        }), _createTextVNode(" " + _toDisplayString(item.viewnum), 1)])])]), _createElementVNode("div", _hoisted_16, [_createElementVNode("img", {
          src: item.imageUrl
        }, null, 8, _hoisted_17)])], 8, _hoisted_11)) : _createCommentVNode("", true)]);
      }), 256))]),
      _: 1
    }, 8, ["loading", "finished", "finished-text", "onLoad"])])]), $data.data.length == 0 ? (_openBlock(), _createBlock(_component_van_empty, {
      key: 0,
      image: "/img/empty.png",
      "image-size": "80",
      description: "暂时还没有新闻哦~"
    })) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["modelValue", "onRefresh"])]), _createVNode(_component_foot_wrap)]);
}