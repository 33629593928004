import "core-js/modules/es.array.push.js";
import topNav from "../../components/common/topNav.vue";
import footWrap from "../../components/common/footWrap.vue";
export default {
  components: {
    topNav,
    footWrap
  },

  data() {
    return {
      scenario: 'newsDetail',
      postData: {
        newsid: 0
      },
      data: [],
      config: []
    };
  },

  created() {
    // 初始化postData
    this.setQuery();
    this.getData();
  },

  methods: {
    getData() {
      this.http('/www/home/news-detail', this.postData, res => {
        if (!res.data.newsid) {
          this.$router.push('/404');
          return;
        } // 数据渲染


        this.data = res.data;
        this.config = res.config;
        document.title = this.data.title; // 浏览记录

        this.http('/www/home/news-view', this.postData, res => {});
      }, null, 2);
    }

  }
};