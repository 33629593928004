import { Empty, Button } from "vant";
export default {
  components: {
    [Empty.name]: Empty,
    [Button.name]: Button
  },

  data() {
    return {};
  },

  created() {},

  methods: {}
};