import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

const app = createApp(App);

// mixin
import Mixin from './mixin';
app.mixin(Mixin);

import { Dialog, Toast, ConfigProvider } from 'vant';

app.use(ConfigProvider);
app.use(Dialog);
app.use(Toast);

import 'vant/es/toast/style';
import 'vant/es/dialog/style';

app.use(router).mount('#app')
