import { ref } from 'vue';
import { Sticky, Popup, Icon } from 'vant';
export default {
  data() {
    return {
      show: false,
      clicked: false
    };
  },

  components: {
    [Sticky.name]: Sticky,
    [Popup.name]: Popup,
    [Icon.name]: Icon
  },

  setup() {
    const showCity = ref(false);

    const showCityMenu = () => {
      showCity.value = !showCity.value;
    };

    return {
      showCity,
      showCityMenu
    };
  },

  methods: {
    closeIcon(flag) {
      this.clicked = flag;
    },

    showMenu(flag) {
      if (this.clicked == true) {
        this.show = false;
        this.clicked = false;
        return;
      }

      this.show = flag;
      this.closeIcon(true);
    }

  }
};