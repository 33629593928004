// mixin 公用方法
import md5 from 'js-md5';
import qs from 'qs';
import axios from 'axios';
import VueCookies from "vue-cookies";

export default {
    data() {
        return {
            initPostData: {},
        }
    },
    methods: {
        setQuery() {
            let param = {};
            for (let i in this.postData) {
                // 保存初始化变量
                this.initPostData[i] = this.postData[i];

                let query = this.$route.query[i] === undefined ? this.$route.params[i] : this.$route.query[i];
                param[i] = query === undefined ? this.postData[i] : query;
            }

            this.postData = param;
        },
        /**
         * 警告弹框
         * @param params 参数 {
         *      title 标题
         *      message 内容
         *      confirmButtonText 确认按钮名称
         *      param 自定义参数
         *  }
         * @param okCallback 确认回调
         */
        alert(params = {}, okCallback) {
            let options = {
                title: params.title ? params.title : '',
                message: params.message ? params.message : '',
                confirmButtonText: params.confirmButtonText ? params.confirmButtonText : '确认',
            };

            this.$dialog.alert(options).then(() => {
                if (okCallback) {
                    let data = params.param ? params.param : '';
                    okCallback(data);
                }
            });
        },
        /**
         * 轻弹框
         * @param params 参数 {
         *      message 内容
         *      duration 关闭时间【展示时长(ms)，值为 0 时，toast 不会消失】
         *      type 提示类型【text、loading、success、fail、html】
         *      forbidClick 是否禁止背景点击
         *      mask 是否显示背景遮罩层
         *      loadingType 加载图标类型【circular、spinner】
         *      param 自定义参数
         *  }
         * @param callback
         */
        toast(params = {}, callback) {
            let options = {
                message: params.message ? params.message : '',
                type: params.type ? params.type : 'text',
                duration: params.duration !== undefined ? params.duration : 1500,
                forbidClick: params.forbidClick !== undefined ? params.forbidClick : true,
                mask: params.mask !== undefined ? params.mask : false,
                onClose: () => {
                    if (callback) {
                        let data = params.param ? params.param : '';
                        callback(data);
                    }
                }
            };
            if (options.type === 'loading') {
                options.loadingType = params.loadingType ? params.loadingType : 'circular';
            }
            this.$toast(options);
        },
        /**
         * 确认弹框
         * @param params 参数 {
         *      title 标题
         *      message 内容
         *      confirmButtonText 确认按钮名称
         *      cancelButtonText 取消按钮名称
         *      param 自定义参数
         *  }
         * @param okCallback 确认回调
         * @param cancelCallback 取消回调
         */
        confirm(params = {}, okCallback, cancelCallback) {
            let options = {
                title: params.title ? params.title : '',
                message: params.message ? params.message : '',
                confirmButtonText: params.confirmButtonText ? params.confirmButtonText : '确认',
                cancelButtonText: params.cancelButtonText ? params.cancelButtonText : '取消',
                // 异步加载时使用
                // beforeClose: (action) => new Promise((resolve) => {
                //     if (action === 'confirm') {
                //         if (okCallback) {
                //             let data = params.param ? params.param : '';
                //             okCallback(data);
                //         }
                //         console.log('ok')
                //         resolve(true);
                //     } else {
                //         if (cancelCallback) {
                //             let data = params.param ? params.param : '';
                //             cancelCallback(data);
                //         }
                //         console.log('cancel')
                //         resolve(true);
                //     }
                // }),
            };

            this.$dialog.confirm(options).then(() => {
                // on confirm
                if (okCallback) {
                    let data = params.param ? params.param : '';
                    okCallback(data);
                }
            }).catch(() => {
                // on cancel
                if (cancelCallback) {
                    let data = params.param ? params.param : '';
                    cancelCallback(data);
                }
            });
        },
        /**
         * Http请求
         * @param url 请求地址
         * @param data 请求参数
         * @param successCallback 成功回调
         * @param errorCallback 错误回调
         * @param lock 是否加锁【0否、1Post、2Get】
         * @returns {boolean}
         */
        http(url, data, successCallback, errorCallback, lock = 0) {

            let params = this.formatParams(data);

            axios({
                method: 'post',
                url: process.env.VUE_APP_WS + url,
                data: qs.stringify(params)
            }).then( res => {

                // TOKEN过期处理
                if (res.data.msgcode === 1700) {
                    // this.toast({message: res.data.msgstr, type: 'fail'}, () => {});
                    // console.log(this.$route);
                    this.logoutRemoveCookie();

                    // 推荐人
                    let supid = this.$route.query.supid;
                    if (supid) {
                        this.setCookie('supid', supid);
                    }

                    this.$router.replace('/user/login?redirectUrl=' + encodeURIComponent(this.$route.fullPath));
                    return;
                }

                // 提示错误信息
                if (res.data.msgcode != 200) {
                    this.alert({title: '操作失败', message: res.data.msgstr});

                    // 失败回调
                    if (errorCallback) {
                        errorCallback(res.data);
                    }

                    return;
                }

                // 成功回调
                if (successCallback) {
                    successCallback(res.data);
                }

            }).catch(function (error) {
                console.log(error);
            });
        },
        /**
         * 上传图片
         * @param url 请求地址
         * @param options 请求参数 {
         *      site: '',
         *      module: '',
         *      imgurl: 1,是否返回图片地址 1是 0否
         *      filestatus: 0,是否上传正式目录 1是 0否
         *      max: '', 大图尺寸  '600,600'
         *      min: '', 小图尺寸  '300,300'
         * }
         * @param successCallback 成功回调
         * @param lock 是否加锁
         * @returns {boolean}
         */
        httpImg(url, options = {}, successCallback, lock = 0) {

            if (!options.filedata) {
                return false;
            }

            let param = new FormData(); // 创建form对象

            // 其他参数
            param.append('site', options.site ? options.site : '');
            param.append('module', options.module ? options.module : '');
            param.append('filestatus', options.filestatus ? options.filestatus : 0);
            param.append('max', options.max ? options.max : '');
            param.append('min', options.min ? options.min : '');
            param.append('filedata', options.filedata ? JSON.stringify(options.filedata) : JSON.stringify({}));

            // 加密验证
            let authkey = new Date().getTime();
            let authcode = md5(authkey + '__' + authkey);
            param.append('authkey', authkey);
            param.append('authcode', authcode);

            axios({
                method: 'post',
                url: process.env.VUE_APP_UPLOAD + '/upload/index',
                data: param,
                headers: { 'Content-Type': 'multipart/form-data' },//添加请求头
            }).then(res => {

                // 错误处理
                if (res.data.code != 200) {
                    this.alert({ content: res.data.message });
                    return;
                }

                if (successCallback) {
                    successCallback(res.data);
                }
            }).catch(function (error) {

                console.log(error);
                // stores.dispatch('hideloader');
            });

        },
        /**
         * 格式化请求参数
         * @param data
         * @returns {{info: string}}
         */
        formatParams(data) {
            if (!data) {
                data = {};
            }

            data.terminal = this.getTerminal();
            data.dateline = parseInt((new Date()).valueOf() / 1000);

            let openid = VueCookies.get('openid');

            if (openid) {
                data.openid = openid;
                data.platform = VueCookies.get('platform') ? VueCookies.get('platform') : '';
            }

            if (this.isLogin()) {
                data.uid = VueCookies.get('uid');
                data.token = VueCookies.get('token');
            }

            data.citycode = VueCookies.get('citycode');

            return data;
        },
        /**
         * 是否登录
         * @returns {boolean}
         */
        isLogin() {
            return VueCookies.get('uid') !== null && VueCookies.get('token') !== null;
        },
        /**
         * 登录
         * @param obj
         */
        loginSetCookie(obj) {
            this.setCookie('uid', obj.uid);
            this.setCookie('token', obj.token);
            this.removeCookie('unfastLogin');

            // this.$store.commit("setLogin", true)
        },
        /**
         * 退出登录
         */
        logoutRemoveCookie() {
            this.removeCookie('uid');
            this.removeCookie('token');

            //this.$store.commit("setLogin", false)
        },
        setKeyword(keyword) {
            if (keyword === '') return;

            let keywords = this.getKeyword();
            keywords = keywords ? keywords : [];

            for (let i in keywords) {
                if (keyword === keywords[i]) {
                    keywords.splice(i, 1);
                    keywords.unshift(keyword);
                    this.setObjectLocalStorage('keyword', keywords);
                    return;
                }
            }

            if (keywords.length >= 10) {
                keywords.pop();
            }

            keywords.unshift(keyword);

            this.setObjectLocalStorage('keyword', keywords);
        },
        getKeyword() {
            let keyword = this.getObjectLocalStorage('keyword');
            return keyword ? keyword : [];
        },
        /**
         * 获取cookie
         *
         */
        getCookie(name) {
            return VueCookies.get(name);
        },
        /**
         * 设置cookie
         * @param name
         * @param value
         * @param expires
         * @param path
         * @param domain
         * @param secure
         */
        setCookie(name, value, expires = 86400, path='/', domain='', secure=false) {
            domain = process.env.VUE_APP_DOMAIN ? process.env.VUE_APP_DOMAIN : '';
            VueCookies.set(name, value, expires, path, domain, secure);
        },
        /**
         * 删除cookie
         * @param name
         * @param path
         * @param domain
         */
        removeCookie(name, path='/', domain='') {
            domain = process.env.VUE_APP_DOMAIN ? process.env.VUE_APP_DOMAIN : '';
            VueCookies.remove(name, path, domain);
        },
        /**
         * 设置localStorage【数据为对象】
         * @param name
         * @param value
         */
        setObjectLocalStorage(name, value) {
            localStorage.setItem(name, JSON.stringify(value));
        },
        /**
         * 获取localStorage【数据为对象】
         * @param name
         * @returns {boolean}
         */
        getObjectLocalStorage(name) {
            let val = localStorage.getItem(name);
            return val ? JSON.parse(val) : false;
        },
        removeObjectLocalStorage(name) {
            localStorage.removeItem(name);
        },
        /**
         * 日期格式化
         * @param date new Date()
         * @returns {{date_str: string, month: (*|number), year: number, day: any | number}}
         */
        getDateFormat(date) {
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            month = month < 10 ? '0'+month : month;
            let day = date.getDate();
            day = day < 10 ? '0'+day : day;

            return {
                year: year,
                month: month,
                day: day,
                date_str: year+'-'+month+'-'+day,
            }
        },
        /**
         * 判断元素是否在数组中
         * @param val
         * @param arr
         * @returns {*}
         */
        inArray(val, arr) {
            /*if(typeof arr !== 'array') {
                return -1;
            }*/
            return $.inArray(val, arr);
        },
        /**
         * 获取终端信息【是否 android\ios\微信浏览器】
         * @returns {terminal}
         */
        getTerminal() {

            if (this.inWechat() !== false) {
                return 3;
            }

            return process.env.VUE_APP_TERMINAL;
        },
        inWechat() {
            let ua = navigator.userAgent.toLowerCase();

            if (ua.match(/MicroMessenger/i) == "micromessenger") {
                return true;
            }

            return false;
        },
        idcardCheck(idcard) {

            if (!idcard) {
                return true;
            }

            if (/^\d\d{16}[\d\x]$/i.test(idcard) === false) {
                return false;
            }

            //区位码校验
            //出生年月日校验   前正则限制起始年份为1900;
            let year = idcard.substr(6,4),//身份证年
                month = idcard.substr(10,2),//身份证月
                date = idcard.substr(12,2),//身份证日
                time = Date.parse(month+'-'+date+'-'+year),//身份证日期时间戳date
                now_time = Date.parse(new Date()),//当前时间戳
                dates = (new Date(year,month,0)).getDate();//身份证当月天数

            if ( time > now_time || date > dates ){
                return false;
            }

            //校验码判断
            let c = new Array(7,9,10,5,8,4,2,1,6,3,7,9,10,5,8,4,2);   //系数
            let b = new Array('1','0','X','9','8','7','6','5','4','3','2');  //校验码对照表
            let id_array = idcard.split("");
            let sum = 0;

            for ( let k=0; k<17; k++ ){
                sum += parseInt(id_array[k]) * parseInt(c[k]);
            }

            if ( id_array[17] && id_array[17].toUpperCase() != b[sum%11].toUpperCase() ){
                return false;
            }
            return true;
        },

        // 电脑打开移动程序时兼容宽度处理
        inherit() {
            if (/Mobi|Android|iPhone|micromessenger/i.test(navigator.userAgent)) return;

            let tag = document.getElementsByTagName('div');

            for (let i in tag) {
                let classname = tag[i].className;

                if (classname == undefined) continue;

                if (classname.indexOf('inherit') !== -1) {
                    tag[i].style.width = '680px';
                    tag[i].style.margin = '0 auto';
                }
            }
        },
        actionTo(url) {
            this.$router.push(url);
        },
        actionReplace(url) {
            this.$router.replace(url);
        },
    }
};