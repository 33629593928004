import shareList from '../views/share/sharelist.vue'
import gongXu from '../views/share/gongxu.vue'

export default [
    {
        path: '/share',
        name: 'share',
        meta: {
            title: '共享用工',
            requireLogin: false,
        },
        component: shareList,
    },
    {
        path: '/share/list',
        name: 'shareList',
        meta: {
            title: '共享用工',
            requireLogin: false,
        },
        component: gongXu,
    },
];