import topNav from "../../components/common/topNav.vue";
import footWrap from "../../components/common/footWrap.vue";
import gongXu from '@/components/modal/gongxu';
import companyLogo from '@/components/modal/companylogo.vue';
import { Space, Tab, Tabs, Swipe, SwipeItem } from 'vant';
import { ref } from 'vue';
export default {
  components: {
    topNav,
    footWrap,
    gongXu,
    companyLogo,
    [Space.name]: Space,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem
  },

  data() {
    return {
      dataG: [],
      dataX: [],
      postData: {},
      show: false
    };
  },

  setup() {
    // tab
    const active = ref(0);
    return {
      active
    };
  },

  created() {
    this.getData();
  },

  methods: {
    getData() {
      // 查询数据
      this.http('/www/home/share', this.postData, res => {
        this.dataG = res.listG.data;
        this.dataX = res.listX.data;
      }, null, 2);
    },

    showPopup() {
      this.show = true;
    },

    submit() {
      this.show = false;
    }

  }
};