import Home from '../views/xscHome';

export default [
    {
        path: '/',
        name: 'Home',
        meta: {
            title: '人力资源蓄水池',
            content: {
                keywords: "人力资源蓄水池",
                description: "安徽省首个政府主导的人力资源服务创新项目",
            },
            requireLogin: false,
            keepAlive: false,
        },
        component: Home,
    },
];