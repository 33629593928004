import newsList from '../views/news/newsList.vue'
import newsDetail from '../views/news/newsDetail.vue'

export default [
    {
        path: '/news',
        name: 'newsList',
        meta: {
            title: '新闻中心',
            requireLogin: false,
            keepAlive: true,
        },
        component: newsList,
    },
    {
        path: '/news/:newsid(\\d+)',
        name: 'newsDetail',
        meta: {
            title: '新闻中心',
            requireLogin: false,
            keepAlive: false,
        },
        component: newsDetail,
    },
];