import { createRouter, createWebHistory } from 'vue-router';
import common from '../assets/js/common';

import home from './home';
import school from './school';
import share from './share';
import news from './news';
import Error from './error';

const routes = [
    ...home,
    ...school,
    ...share,
    ...news,
    ...Error,
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from) => {

    // 保持缓存页点击位置
    if (from.meta.keepAlive) {
        const $content = document.querySelector('#content');
        const scrollTop = $content ? $content.scrollTop : 0;
        from.meta.scrollTop = scrollTop;
    }

    // 微信授权
    if (common.inWechat() !== false && false) {
        // 快速登录
        common.getWechatOpenid(to);

        // 分享配置
        if (to.fullPath.indexOf('/job/') === -1) {
            common.getWechatShareConfig(to.fullPath);
        }
    }

    //console.log(to);
    // console.log(from);

    // 设置标题
    document.title = to.meta.title;

    if(to.meta.content){
        let head = document.getElementsByTagName('head');
        let meta = document.createElement('meta');
        document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
        document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
        meta.content = to.meta.content;
        head[0].appendChild(meta)
    }
});

router.afterEach((to, from) => {
    // 新打开页面，跳转至顶部
    window.scrollTo(0,0);
});

export default router
